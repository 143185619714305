import {FormControl, Grid, InputLabel, MenuItem, Select, Switch,} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {useParams} from "react-router"
import {MainAPI} from "../core/api/FApi"

export const PermissionEditor = (props) => {
    let {userid} = useParams<Record<string, string | undefined>>()
    const columns: any = [
        {title: "id", field: "id", hidden: true, type: "numeric"},
        // {title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.first_name} />  },
        {title: "Permission", field: "name", type: "string"},
        {
            title: "Grant",
            field: "grant",
            type: "boolean",
            render: data => <PropertySwitch checked={data.active} perm={data.id} userid={userid}/>
        },
    ]
    //const [data, setData] = React.useState(null) //table data
    const updateAccount = () => {
        MainAPI.accountsGet(userid).then((account) => {
            setAccount(account)
        }).catch(()=>{})
        return null
    }
    const updateRoles = () => {
        MainAPI.rolesList({pageSize: 300}).then((roles) => {
            //console.log(roles)
            setRoles(roles.result)
        }).catch(()=>{})
        return null
    }

    const [account, setAccount] = React.useState(null)
    const [roles, setRoles] = React.useState(null)

    if (account == null) updateAccount()
    if (roles == null) updateRoles()

    const handleChange = (event) => {
        //setAge(event.target.value as string)
        // console.log("Changed to " + event.target.value)
        account.role_id = event.target.value
        setAccount(account)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={1}/>
            {account == null || roles == null ?
                <Grid item xs={12}>
                    Loading...
                </Grid> :
                <Grid item xs={6}>
                    <h1>User #{account.id}</h1>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <FormControl>
                                <InputLabel id="role-sel-lbl">Role</InputLabel>
                                <Select
                                    labelId="role-sel-lbl"
                                    id="role-sel"
                                    value={account.role_id}
                                    onChange={handleChange}
                                >
                                    {roles.map((elem) => <MenuItem value={elem.id}>{elem.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <MaterialTable
                                title="Permissions"
                                columns={columns}
                                options={{search: false}}
                                data={(query) =>
                                    MainAPI.permissionsList(query, {user_id: userid}).then((result) => {
                                        return {
                                            data: result.result,
                                            page: query.page,
                                            totalCount: result.count,
                                        }
                                    })
                                }
                                icons={props.tableIcons}
                                editable={
                                    {
                                        /*
                                    onRowUpdate: (newData, oldData) =>
                                      new Promise((resolve) => {
                                        resolve(true)
                                        //props.handleRowUpdate(newData, oldData, resolve)
                                      }),
                                    onRowAdd: (newData) =>
                                      new Promise((resolve) => {
                                        props.handleRowAdd(newData, resolve)
                                      }),*/
                                        //onRowDelete: req => MainAPI.removeReport(req.id),
                                    }
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={1}/>
        </Grid>
    )
}

const PropertySwitch = (props) => {
    const [durty, setDurty] = React.useState(false)
    const [checked, setChecked] = React.useState(props.checked === 1)
    return (
        <Switch
            checked={checked}
            disabled={durty}
            onChange={(evt) => {
                evt.preventDefault()
                evt.stopPropagation()
                setDurty(true)
                MainAPI.toggleUserPerm(props.perm, props.userid, !checked).then(() => {
                    setDurty(false)
                    setChecked(!checked)
                }).catch(() => {
                    setDurty(false)
                    setChecked(checked)
                })
                //event.target.checked
            }}
            color="primary"
        />
    )
}
