import {Button, Grid} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {MainAPI} from "../core/api/FApi"
import FirmwareUploadDialog from "../common/FirmwareUploadDialog"
import {t} from "../core/lang/lang"

var firmwaresTableRef

export const FirmwaresList = (props) => {
    const [dialogOpen, setOpenDialog] = React.useState(false)
    const [columns, setColumns] = React.useState(null)
    let tableRef = React.createRef()
    firmwaresTableRef = tableRef

    if (columns == null) {
        MainAPI.editorsGetFields("firmware").then((data) => {
            setColumns(data.fields)
        }).catch(()=>{})
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <Grid container spacing={1}>
                <Grid item xs={1}/>
                <Grid item xs={12}>
                    <FirmwareUploadDialog
                        open={dialogOpen}
                        handleClose={() => {firmwaresTableRef.current.onQueryChange(); setOpenDialog(false)}}
                    />
                    <Button onClick={() => setOpenDialog(true)}>Добавить прошивку</Button>
                    <MaterialTable
                        title={t("FIRMWARES")}
                        columns={columns}
                        tableRef={tableRef}
                        options={{
                            pageSize: 10, search: false, filtering: true
                        }}
                        data={(query) =>
                            MainAPI.firmwaresList(query).then((result) => {
                                return {
                                    data: result.result,
                                    page: query.page,
                                    totalCount: result.count,
                                }
                            })
                        }
                        icons={props.tableIcons}
                        editable={{
                            onRowUpdate: (newData) => MainAPI.firmwaresUpdate(newData),
                            onRowDelete: (req: any) => MainAPI.firmwaresDelete(req.id),
                        }}
                    />
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        )
}