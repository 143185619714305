import {Button, Grid} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {MainAPI} from "../core/api/FApi"
import {showExportAlertAction} from "../core/redux/actions/PageStateActions"
import store from "../store"
import {t} from "../core/lang/lang"
import DeviceInfoAlert from "../common/DeviceInfoAlert"
import {connect} from "react-redux";

export var reportsTableRef

const generateExport = (kind, request) => {
    store.dispatch(showExportAlertAction(kind, request))
}

const PageLastReports = (props) => {
    const [columns, setColumns] = React.useState(null)
    const [notif, setNotif] = React.useState({open: false, deviceId: -1})
    const tableRef = React.createRef()

    const hasWritePermission = props.userOptions?.perms?.includes("table_reports_rw")

    reportsTableRef = tableRef
    let lastQueryParams = {}

    if (columns == null) {
        MainAPI.editorsGetFields("report").then((data) => {
            setColumns(data.fields.map(item => {
                if (item.field === "device_serial_number")
                    return {
                        ...item,
                        render: rowData => <Button onClick={() => {
                            if (rowData.device_id > 0) setNotif({open: true, deviceId: rowData.device_id})
                        }} variant="contained" color="primary">{rowData.device_serial_number}</Button>
                    }
                else
                    return item
            }))
        }).catch(()=>{})
        return (
            <>
                <Grid container alignContent="center" spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <>
                <DeviceInfoAlert
                    open={notif.open}
                    handleClose={() => setNotif({open: false, deviceId: -1})}
                    data={notif.open ? notif : null}
                />
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        <MaterialTable
                            title={t("REPORTS")}
                            columns={columns}
                            tableRef={tableRef}

                            options={{
                                pageSize: 10,
                                search: false,
                                filtering: true,
                                exportMenu: [{
                                    label: 'Export Excel',
                                    exportFunc: () => generateExport("reports", lastQueryParams)
                                }]
                                // exportButton: {csv: true},
                                // exportCsv: (columns, data) =>
                                //     generateExport("reports", lastQueryParams)
                                // ,
                            }}
                            // localization={{toolbar: {exportCSVName: "Export Excel"}}}
                            data={(query) => {
                                lastQueryParams = query
                                return MainAPI.reportsList(query).then((result) => {
                                    return {
                                        data: result.result,
                                        page: query.page,
                                        totalCount: result.count,
                                    }
                                })
                            }}
                            icons={props.tableIcons}
                            editable={hasWritePermission ? {
                                onRowDelete: (req: any) => MainAPI.reportsDelete(req.id),
                            } : {}}
                        />
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(PageLastReports)
