import {Grid, Switch,} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {useParams} from "react-router"
import {MainAPI} from "../core/api/FApi"

export const RoleEditorPage = (props) => {
    let {roleid} = useParams<Record<string, string | undefined>>()
    const tableRef = React.createRef()
    const columns: any = [
        {title: "id", field: "id", hidden: true, type: "numeric"},
        // {title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.first_name} />  },
        {title: "Разрешение", field: "name", type: "string"},
        {
            title: "Включить", field: "grant", type: "boolean", render: data => <PropertySwitch setChecked={(state) => {
                data.checked = state
                handleChange()
            }} checked={data.active} perm={data.id} role={roleid}/>
        },
    ]
    const [role, setRole] = React.useState(null)

    const handleChange = () => {
        // @ts-ignore
        tableRef.current.onQueryChange()
    }

    const updateAccount = () => {
        //MainAPI.permissionsList({},{role: roleid}).then(result => setPerms(result))
        MainAPI.rolesGet(roleid).then((account) => {
            setRole(account)
        }).catch(()=>{})
    }

    if (role == null) updateAccount()

    return (
        <Grid container spacing={1}>
            <Grid item xs={1}/>
            {role == null ?
                <Grid item xs={12}>
                    Loading...
                </Grid> :
                <Grid item xs={6}>
                    <h1>Role "{role.name}" #{role.id}</h1>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <MaterialTable
                                title="Permissions"
                                columns={columns}
                                tableRef={tableRef}
                                options={{
                                    pageSize: 10, search: false
                                }}
                                data={(query) =>
                                    MainAPI.permissionsList(query, {role_id: roleid}).then((result) => {
                                        return {
                                            data: result.result,
                                            page: query.page,
                                            totalCount: result.count,
                                        }
                                    })}
                                icons={props.tableIcons}
                                editable={
                                    {
                                        /*
                                    onRowUpdate: (newData, oldData) =>
                                      new Promise((resolve) => {
                                        resolve(true)
                                        //props.handleRowUpdate(newData, oldData, resolve)
                                      }),
                                    onRowAdd: (newData) =>
                                      new Promise((resolve) => {
                                        props.handleRowAdd(newData, resolve)
                                      }),*/
                                        //onRowDelete: req => MainAPI.removeReport(req.id),
                                    }
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={1}/>
        </Grid>
    )
}

const PropertySwitch = (props) => {
    const [durty, setDurty] = React.useState(false)
    //const [checked, setChecked] = React.useState(props.checked)
    const checked = props.checked
    //console.log("render " + props.perm)

    return (
        <Switch
            checked={checked}
            disabled={durty}
            onChange={(evt) => {
                evt.preventDefault()
                evt.stopPropagation()
                setDurty(true)
                MainAPI.toggleRolePerm(props.perm, props.role, !checked).then(() => {
                    setDurty(false)
                    props.setChecked(!checked)
                }).catch(() => {
                    setDurty(false)
                    props.setChecked(checked)
                })
                //event.target.checked
            }}
            color="primary"
        />
    )
}
