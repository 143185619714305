import React, {ChangeEvent} from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper, {PaperProps} from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Slide from "@material-ui/core/Slide"
import {TransitionProps} from "@material-ui/core/transitions"
import {MainAPI} from "../core/api/FApi"
import {Grid, TextField} from "@material-ui/core"
import {uploadFile} from "../utils/UploadUtils";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

export default function FirmwareUploadDialog(props) {
    let firmware = {id: -1, note: "V. ", file_id: ""}
    const [fields, setFields] = React.useState([])
    let file

    const updateAccount = () => {
        MainAPI.editorsGetFields("firmware").then((data) => {
            setFields(data.fields)
        }).catch(()=>{})
    }

    // On file select (from the pop up)
    const onFileChange = event => {
        // Update the state
        // this.setState({ selectedFile: event.target.files[0] })
        file = event.target.files[0]
    }

    if (fields.length < 1) updateAccount()

    const onElementChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const id = event.target.id as string
        const fieldtag = id.substring(1)
        switch (id.charAt(0)) {
            case "t":
            case "n":
                firmware[fieldtag] = event.target.value
                break
            case "r":
                break
        }
    }

    async function submitDataset() {
        try {
            let descriptorHex = await MainAPI.getUploadDescriptor('firmware.hex')
            let fileResult = await uploadFile(file, window.location.protocol + "//" + window.location.hostname + ":8880/upl.php?t=" + descriptorHex.token)
            if (fileResult.data === "ok") {
                let binFileDescriptor = await MainAPI.consumeHexFile(descriptorHex.token)
                firmware.file_id = binFileDescriptor.token
                await MainAPI.firmwaresCreate(firmware)
                props.handleClose()
            }
        } catch (e) {
            
        }
    }


    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement<any, any> },
        ref: React.Ref<unknown>
    ) {
        return <Slide direction="up" ref={ref} {...props} />
    })

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            keepMounted
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                Upload firmware
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <input type="file" onChange={onFileChange}/>
                    </Grid>
                    {/*<FilePond*/}
                    {/*    // ref={ref => (this.pond = ref)}*/}
                    {/*    allowMultiple={false}*/}
                    {/*    allowReorder={false}*/}
                    {/*    maxFiles={1}*/}
                    {/*    server="/api"*/}
                    {/*    name="firmware.hex"*/}
                    {/*    // oninit={() => this.handleInit()}*/}
                    {/*    onupdatefiles={fileItems => {*/}
                    {/*        // Set currently active file objects to this.state*/}
                    {/*        // this.setState({*/}
                    {/*        //     files: fileItems.map(fileItem => fileItem.file)*/}
                    {/*        // })*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {fields.map((entry) => {
                        if (!entry.hidden)
                            switch (entry.type) {
                                case "string":
                                    return (entry.input = (
                                        <Grid item>
                                            <TextField
                                                id={"t" + entry.field}
                                                label={entry.title}
                                                disabled={entry.editable !== "always"}
                                                defaultValue={firmware[entry.field]}
                                                variant="outlined"
                                                onChange={onElementChange}
                                            />
                                        </Grid>
                                    ))
                                case "number":
                                    return (entry.input = (
                                        <Grid item>
                                            <TextField
                                                id={"n" + entry.field}
                                                label={entry.title}
                                                variant="outlined"
                                                disabled={entry.editable !== "always"}
                                                defaultValue={firmware[entry.field]}
                                                onChange={onElementChange}
                                            />
                                        </Grid>
                                    ))
                            }
                        return null
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={submitDataset} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}