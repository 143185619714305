import {ValidationSchema, Validators} from "@lemoncode/fonk"
import {createFormikValidation} from "@lemoncode/fonk-formik"

const validationSchema: ValidationSchema = {
    field: {
        login: [Validators.required],
        password: [Validators.required],
    },
}

export const loginFormValidation = createFormikValidation(validationSchema)
