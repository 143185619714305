import {Button, Grid, TextField} from "@material-ui/core"
import {ExitToApp} from "@material-ui/icons"
import * as React from "react"
import {MainAPI} from "../core/api/FApi"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {t} from "../core/lang/lang"

export const SettingsPage = (props) => {
    const [schema, setSchema] = React.useState(null)
    const [userSchema, setUserSchema] = React.useState(null)

    if (schema === null) {
        MainAPI.getSettingsSchema().then((data) => setSchema(data.schema)).catch(()=>{})
    }
    if (userSchema === null) {
        MainAPI.getSettingsSchema().then((data) => setUserSchema(data.schema)).catch(()=>{})
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                <h1>{t("SETTINGS")}</h1>
                <Grid container direction="column" spacing={1}>
                    {schema === null ? (
                        <Grid item xs={12}>
                            Loading...
                        </Grid>
                    ) : (
                        <SettingsView schema={schema}/>
                    )}
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                MainAPI.logout()
                                window.location.reload()
                            }}
                            endIcon={<ExitToApp/>}
                        >
                            Выход из аккаунта
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    )
}
const SettingValue = (props) => {
    const [durty, setDurty] = React.useState(false)
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            spacing={1}
        >
            <Grid item xs={9}>
                <TextField
                    id={"t" + props.perm.tag}
                    label={props.perm.name}
                    disabled={!props.perm.writable}
                    defaultValue={props.perm.value}
                    style={{width: "100%"}}
                    variant="outlined"
                    onChange={(val) => {
                        props.perm.value = val.target.value
                        setDurty(true)
                    }}
                />


            </Grid>
            {durty ? (
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            MainAPI.updateProperty(props.perm.tag, props.perm.value).then(
                                () => setDurty(false)
                            ).catch(()=>{})
                        }}
                    >
                        Update
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    )
}
const SettingsView = (props) => {
    const schema = props.schema
    return <Grid item xs={12}>
        {schema.groups.map((group, i) => (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={"panel" + i + "-content"}
                    id={"panel" + i + "-header"}
                >
                    <Typography>{group.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column" spacing={1}>
                        {schema.props
                            .filter((entry) => entry.pgroup === group.tag)
                            .map((perm) => {
                                if (perm.readable)
                                    switch (perm.type) {
                                        case "string":
                                            return (
                                                <Grid item key={perm.tag}>
                                                    <SettingValue perm={perm}/>
                                                </Grid>
                                            )
                                        case "number":
                                            return (
                                                <Grid item key={perm.tag}>
                                                    <SettingValue perm={perm}/>
                                                </Grid>
                                            )
                                    }
                                return null
                            })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        ))}
    </Grid>
}

