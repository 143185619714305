import {Chip, Grid} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {useEffect} from "react"
import {useHistory} from "react-router-dom"
import {MainAPI} from "../core/api/FApi"
import {t} from "../core/lang/lang"
import {connect} from "react-redux";

var devicesTableRef

const DevicesPage = (props) => {
    let history = useHistory()
    let tableRef = React.createRef()
    devicesTableRef = tableRef
    const [columns, setColumns] = React.useState(null)

    const hasWritePermission = props.userOptions?.perms?.includes("table_devices_rw")

    useEffect(() => {
        // @ts-ignore
        var interval = setInterval(() =>
            devicesTableRef.current.onQueryChange(), 60000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    if (columns == null) {
        MainAPI.editorsGetFields("device").then((data) => {
            setColumns(data.fields.map(item => {
                if (item.field === "online")
                    return {
                        ...item,
                        render: rowData => <Chip color={rowData.online === 1 ? 'primary' : 'secondary'} size="small"
                                                 label={rowData.online === 1 ? 'online' : 'offline'}/>
                    }
                else
                    return item
            }))
        }).catch(()=>{})
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <Grid container spacing={1}>
                <Grid item xs={1}/>
                <Grid item xs={12}>
                    <MaterialTable
                        title={t("DEVICES")}
                        columns={columns}
                        tableRef={tableRef}
                        options={{
                            pageSize: 10, search: false, filtering: true
                        }}
                        data={(query) =>
                            MainAPI.devicesList(query).then((result) => {
                                return {
                                    data: result.result,
                                    page: query.page,
                                    totalCount: result.count,
                                }
                            })
                        }
                        actions={[
                            {
                                icon: "check_circle_outline",
                                tooltip: t("TOIR"),
                                onClick: (event, rowData) => {
                                    history.push("/toir/" + rowData["id"])
                                },
                            },
                            hasWritePermission ? {
                                icon: "title",
                                tooltip: "Перезагрузка отчётов с устройства",
                                onClick: (event, rowData) => {
                                    MainAPI.devicesActionRefreshHistory(rowData["id"])
                                    //istory.push("/accounts/edit/" + rowData["id"])
                                },
                            } : null,
                            hasWritePermission ? {
                                icon: "network_check",
                                tooltip: "Диагностика",
                                onClick: (event, rowData) => {
                                    MainAPI.devicesActionSelfDiagnosticsStart(rowData["id"])
                                    //history.push("/accounts/edit/" + rowData["id"])
                                },
                            } : null,
                        ]}
                        icons={props.tableIcons}
                        editable={hasWritePermission ? {
                            onRowUpdate: (newData) => MainAPI.devicesUpdate(newData),
                            onRowAdd: (newData) => MainAPI.devicesCreate(newData),
                            onRowDelete: (req: any) => MainAPI.devicesDelete(req.id),
                        } : {}}
                    />
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(DevicesPage)