import React from "react"
import PropTypes from "prop-types"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import {connect} from "react-redux"

const TMNavHeader = ({userData, collapsed}) => {
    // const [login, setLogin] = React.useState<string>("Loading...")
    // const [email, setEmail] = React.useState<string>("...")
    // if (email === "...")
    //     updateUser(setLogin, setEmail)
    return (
        <>
            <div style={{padding: collapsed ? 8 : 16, transition: "0.3s"}}>
                <Avatar
                    style={{
                        width: collapsed ? 48 : 60,
                        height: collapsed ? 48 : 60,
                        transition: "0.3s",
                    }}
                />

                <div style={{paddingBottom: 16}}/>
                <Typography variant={"h6"} noWrap>
                    {userData?.name}
                </Typography>
                <Typography color={"textSecondary"} noWrap gutterBottom>
                    {userData?.email}
                </Typography>
            </div>
            <Divider/>
        </>
    )
}

TMNavHeader.propTypes = {
    collapsed: PropTypes.bool,
}
TMNavHeader.defaultProps = {
    collapsed: false,
}

const mapStateToProps = (state) => {
    return {
        userData: state.loadedOptions.userData,
    }
}
export default connect(mapStateToProps)(TMNavHeader)
