//export var DEF_API_URL = "ws://192.168.1.111:8800" //mi pad 4

//export var DEF_API_URL = "ws://192.168.1.38:8800" //tablet

// export var DEF_API_URL = "ws://192.168.1.149:8800" //redmi

// export var DEF_API_URL = "ws://10.10.25.23:8800" //IPSG route to redmi

export var DEF_API_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
    "ws://localhost:8800" : //dev
    "wss://tmonitor.ru:8800" //prod

