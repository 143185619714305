import {emitCustomEvent} from "react-custom-events"
import {LoginEntity} from "../model/login"
import store from "../../store"
import {Log} from "../../utils/Log"
import {TokenManager} from "../../utils/TokenManager"
import {Utils} from "../../utils/Utils"
import {selectPageAction, setThemeAction} from "../redux/actions/PageStateActions"
import {DEF_API_URL} from "../Config"
import {FWebsocket} from "../ws/FWebsocket"
import {FWSError} from "../ws/FWSError"
import {setUserDataAction, setUserOptionsAction} from "../redux/actions/LoadedOptionsState"

export class FApi {
    ws: FWebsocket
    authRetryCount = 0
    isConnected = false

    public constructor() {
        this.ws = new FWebsocket(DEF_API_URL, this.authByToken)
        const token = TokenManager.getToken()
        if (token !== null) {
            emitCustomEvent("update_auth", true)
        }
    }

    authCompleted = () => {
        this.isConnected = true
        FApi.updateUserInfo()
        FApi.updateUserData()
    }

    public static updateUserData() {
        MainAPI.accountsGet(TokenManager.getUID()).then((result) => {
            store.dispatch(setUserDataAction(result))
        }).catch(() => {
        })
    }

    public static updateUserInfo() {
        MainAPI.userGetUserSettings().then((result) => {
            store.dispatch(setThemeAction(result.isDark))
            store.dispatch(setUserOptionsAction(result))
        }).catch(() => {
        })
    }

    authByForm = (loginInfo: LoginEntity) => {
        return new Promise<string>((resolve, reject) => {
            this.ws
                .promise("security.userauth", {login: loginInfo.login})
                .then((result) => {
                    this.ws
                        .promise("security.auth2", {
                            hash: Utils.MD5(Utils.MD5(loginInfo.password) + result.hash),
                        })
                        .then((result) => {
                            TokenManager.setToken(result.token)
                            TokenManager.setUID(result.uid)

                            this.authCompleted()
                            store.dispatch(selectPageAction(1))
                            resolve(result.token)
                        })
                        .catch((e) => {
                            reject(e)
                        })
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    logout = () => {
        TokenManager.setToken(null)
        store.dispatch(selectPageAction(0))
    }

    exportFile = (type, params, count) => {
        return this.ws.promise("crud." + type, {t: "e", p: params, o: {count}})
    }

    getUploadDescriptor = (name: string) => {
        return this.ws.promise("uploads.uploadFile", {name})
    }

    consumeHexFile = (token: string) => {
        return this.ws.promise("firmwares.consumeHEXFile", {token})
    }

    getDevices = () => {
        return this.ws.promise("devices.get", {id: -1})
    }

    getSettingsSchema = () => {
        return this.ws.promise("settings.getSettingsConfig", {})
    }
    getUserSettingsSchema = () => {
        return this.ws.promise("settings.getUserSettingsConfig", {})
    }

    setUserPassword = (userId: Number, password: string) => {
        return this.ws.promise("settings.setUserPassword", {userId: userId, password: password})
    }

    toggleRolePerm = (permId: string, roleId: string, active: boolean) => {
        return this.ws.promise("permissions.toggleRolePerm", {permId: permId, roleId: roleId, active: active})
    }

    toggleUserPerm = (permId: string, userId: string, active: boolean) => {
        return this.ws.promise("permissions.toggleUserPerm", {permId: permId, userId: userId, active: active})
    }

    updateProperty = (tag: string, value: string) => {
        return this.ws.promise("settings.setSysProperty", {tag: tag, value: value})
    }

    updateUserProperty = (tag: string, value: string) => {
        return this.ws.promise("settings.setUserProperty", {tag: tag, value: value})
    }

    editorsAccount = () => {
        return this.ws.promise("editors.get", {t: "account"})
    }

    permissionsList = (params, options) => {
        return this.ws.promise("crud.perms", {t: "l", p: params, o: options})
    }

    //>>> Accounts
    userApplyRole = (userId, roleId) => {
        return this.ws.promise("permissions.assignUserRole", {userId: userId, roleId: roleId})
    }
    userSetTheme = (theme) => {
        return this.ws.promise("settings.setTheme", {dark: theme})
    }
    userGetUserSettings = () => {
        return this.ws.promise("settings.getUserSettings", {})
    }
    accountsList = (params) => {
        return this.ws.promise("crud.accounts", {t: "l", p: params})
    }
    accountsDelete = (id) => {
        return this.ws.promise("crud.accounts", {t: "d", i: id})
    }
    accountsGet = (id) => {
        return this.ws.promise("crud.accounts", {t: "g", i: id})
    }
    accountsCreate = (params) => {
        return this.ws.promise("crud.accounts", {t: "i", d: params})
    }
    accountsUpdate = (params) => {
        return this.ws.promise("crud.accounts", {
            t: "u",
            i: params.id,
            d: params,
        })
    }
    //<<< Accounts

    //>>> Toir

    toirList = (params, device_id) => {
        return this.ws.promise("crud.toir", {t: "l", p: params, o: {device_id: device_id}})
    }
    toirDelete = (id) => {
        return this.ws.promise("crud.toir", {t: "d", i: id})
    }
    toirGet = (id) => {
        return this.ws.promise("crud.toir", {t: "g", i: id})
    }
    toirCreate = (params) => {
        return this.ws.promise("crud.toir", {t: "i", d: params})
    }
    toirUpdate = (params) => {
        return this.ws.promise("crud.toir", {
            t: "u",
            i: params.id,
            d: params,
        })
    }
    //<<< Toir

    //>>> Firmwares
    firmwaresList = (params) => {
        return this.ws.promise("crud.firmwares", {t: "l", p: params})
    }
    firmwaresDelete = (id) => {
        return this.ws.promise("crud.firmwares", {t: "d", i: id})
    }
    firmwaresGet = (id) => {
        return this.ws.promise("crud.firmwares", {t: "g", i: id})
    }
    firmwaresCreate = (params) => {
        return this.ws.promise("crud.firmwares", {t: "i", d: params})
    }
    firmwaresUpdate = (params) => {
        return this.ws.promise("crud.firmwares", {
            t: "u",
            i: params.id,
            d: params,
        })
    }
    //<<< Firmwares

    //>>> Roles
    rolesList = (params) => {
        return this.ws.promise("crud.roles", {t: "l", p: params})
    }
    rolesDelete = (id) => {
        return this.ws.promise("crud.roles", {t: "d", i: id})
    }
    rolesGet = (id) => {
        return this.ws.promise("crud.roles", {t: "g", i: id})
    }
    rolesCreate = (params) => {
        return this.ws.promise("crud.roles", {t: "i", d: params})
    }
    rolesUpdate = (params) => {
        return this.ws.promise("crud.roles", {
            t: "u",
            i: params.id,
            d: params,
        })
    }
    //<<< Roles

    //>>> Devices
    devicesList = (params) => {
        return this.ws.promise("crud.devices", {t: "l", p: params})
    }
    devicesDelete = (id) => {
        return this.ws.promise("crud.devices", {t: "d", i: id})
    }
    devicesGet = (id) => {
        return this.ws.promise("crud.devices", {t: "g", i: id})
    }
    devicesCreate = (params) => {
        return this.ws.promise("crud.devices", {t: "i", d: params})
    }
    devicesUpdate = (params) => {
        return this.ws.promise("crud.devices", {t: "u", i: params.id, d: params})
    }
    devicesActionRefreshHistory = (id) => {
        return this.ws.promise("devices.actions.refreshHistory", {id: id})
    }
    devicesActionSelfDiagnosticsStart = (id) => {
        return this.ws.promise("devices.actions.selfDiagnosticsStart", {id: id})
    }
    //<<< Devices

    //>>> Diagnostics
    diagnosticsList = (params) => {
        return this.ws.promise("crud.diagnostics", {t: "l", p: params})
    }
    diagnosticsDelete = (id) => {
        return this.ws.promise("crud.diagnostics", {t: "d", i: id})
    }
    diagnosticsGet = (id) => {
        return this.ws.promise("crud.diagnostics", {t: "g", i: id})
    }
    //<<< Diagnostics

    //>>> Reports
    reportsList = (params) => {
        return this.ws.promise("crud.reports", {t: "l", p: params})
    }
    reportsDelete = (id) => {
        return this.ws.promise("crud.reports", {t: "d", i: id})
    }
    //<<< Reports

    editorsGetFields = (type) => {
        return this.ws.promise("editors.get", {t: type})
    }

    authByToken = () => {
        const token = TokenManager.getToken()
        if (token !== null) {
            Log.log("Authorizing by token")
            this.ws
                .promise("security.token", {token: token})
                .then((result) => {
                    Log.log("successful auth, uid " + result.uid)
                    TokenManager.setUID(result.uid)

                    this.authCompleted()
                    store.dispatch(selectPageAction(1))
                })
                .catch((e) => {
                    Log.log("Error while authentificating " + e)
                    if (e instanceof FWSError && this.authRetryCount++ > 3)
                        store.dispatch(selectPageAction(0))
                    else if (this.authRetryCount <= 3) this.authByToken()
                })
        }
    }
}

export const MainAPI = new FApi()
