import "./overlay.css"
import * as React from "react"
import {connect} from "react-redux"
import {Grid} from "@material-ui/core"
import {Alert, AlertTitle} from "@material-ui/lab"
import {CSSTransition} from 'react-transition-group'

const AppWarningOverlay = (props) => {

    // const [inProp, setInProp] = useState(true);

    return <CSSTransition
        in={props.connState !== WebSocket.OPEN}
        unmountOnExit
        classNames="alert"
        timeout={200}
    >
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            className={"overlay_window"}
            //onClick={() => setInProp(false)}
        >

            <Grid item xs={10} spacing={10}>
                {/*    <Card>*/}
                {/*    <CardHeader*/}
                {/*        title={`Test`}*/}
                {/*        subheader={`textttt`}*/}
                {/*    />*/}
                {/*    <CardContent>*/}
                {/*        <Typography variant="h5" gutterBottom>*/}
                {/*            Hello World*/}
                {/*        </Typography>*/}
                {/*    </CardContent>*/}
                {/*        */}
                {/*</Card>*/}

                <Alert severity="warning" variant={"filled"}>
                    <AlertTitle>Проблема!</AlertTitle>
                    <p>Нет подключения к серверу. Проверьте соединение с Интернетом.</p>
                    <p>Если соединение с Интернетом есть - попробуйте перезагрузить страницу</p>
                </Alert>
            </Grid>

        </Grid>
    </CSSTransition>
    // <div className="overlay_window">
    //     {/*<div className="overlay_display">*/}
    //     {/*    text*/}
    //     {/*</div>*/}
    // </div>
}

const mapStateToProps = (state) => {
    return {
        connState: state.connState.connState,
    }
}

export default connect(mapStateToProps)(AppWarningOverlay)