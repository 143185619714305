import {showAlertAction, showReportAlertAction} from "../redux/actions/PageStateActions"
import store from "../../store"
import {consumeNetworkEvent} from "../netevents/EventConsumer"

export const processRequest = (rid: string, data: any): Promise<any> => {
    // console.log("request "+rid)

    switch (rid) {
        case 'notify.showNotification':
            store.dispatch(showAlertAction(data))
            return new Promise((resolve) => ({ack: true}))
        case 'notify.showDeviceAlert':
            store.dispatch(showReportAlertAction(data))
            return new Promise((resolve) => ({ack: true}))
        case 'notify.event':
            consumeNetworkEvent(data.name, data.data !== undefined ? JSON.parse(data.data) : null)
            return new Promise((resolve) => ({ack: true}))

    }


}
