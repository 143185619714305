import {processRequest} from "../api/RequestsDispatcher"
import {BiKeyMap} from "../../utils/BiKeyMap"
import {Log} from "../../utils/Log"
import {FWSError} from "./FWSError"
import {Request} from "./FWSRequest"
import {Snacks} from "../Snacks"
import {MainAPI} from "../api/FApi";
import store from "../../store";
import {setUserDataAction} from "../redux/actions/LoadedOptionsState";
import {setConnectionState} from "../redux/actions/ConnectionState";

export class FWebsocket {
    private i = 0
    private socket!: WebSocket
    private reconnectsCount = 0
    private readonly address: string
    private readonly onLoad: Function
    private requests: BiKeyMap<Request> = new BiKeyMap<Request>()

    public constructor(addr: string, onLoad: Function) {
        this.address = addr
        this.onLoad = onLoad
        this.createSocket()
    }

    createSocket = () => {
        this.socket = new WebSocket(this.address)
        this.socket.onclose = this.onDisconnect
        this.socket.onopen = this.onConnect
        this.socket.onmessage = this.onData
    }

    promise = (method: string, data: object): Promise<any> => {
        const request = {
            i: this.i++,
            m: method,
            d: data,
        }
        console.log("sent ", request)
        const stringed = JSON.stringify(request)
        return new Promise((resolve, reject) => {
            this.requests.set(
                request.i,
                method,
                new Request(resolve, reject, stringed)
            )
            this.send(stringed)
        })
    }

    send = (data: string) => {
        this.socket.send(data)
    }

    onConnect = () => {
        store.dispatch(setConnectionState(WebSocket.OPEN, this.reconnectsCount))
        if (this.onLoad !== undefined) {
            this.onLoad()
        }
    }

    onDisconnect = () => {
        MainAPI.isConnected = false
        this.onOffline()
        this.createSocket()
    }

    private onOffline() {
        this.reconnectsCount++
        store.dispatch(setConnectionState(WebSocket.CLOSED, this.reconnectsCount))
    }

    respond = (i: number, meth: string, data: any) => {
        this.send(JSON.stringify({i: i, r: meth, d: data}))
    }

    respondError = (i: number, meth: string, err: string) => {
        this.send(JSON.stringify({i: i, e: 1000, m: err}))
    }

    onData = (data: MessageEvent) => {
        const message = JSON.parse(data.data)
        console.log("received ", data.data)
        if (message.i === undefined || (message.m === undefined && message.r === undefined)) {
            Log.log("message from server lacks required fiels " + data.data)
            return
        }
        if (message.m !== undefined && message.e === undefined) {
            // is a request
            Log.log("request " + message.m)
            processRequest(message.m, message.d).then((data: any) => {
                this.respond(message.i, message.m, data)
            }).catch(() => {
            })
            return
        }
        const callbacks = this.requests.get(message.i, message.r)
        if (callbacks == null) {
            Log.log("request " + message.r + ":" + message.i + " wasn't found")
            return
        }
        if (message.e !== undefined) {
            // error
            Snacks.error("Ошибка: " + message.m)
            try {
                callbacks.error(new FWSError(message.m, message.r, message.e))
            } catch(e) {

            }
        } else {
            callbacks.resolve(message.d)
        }
    }
}
