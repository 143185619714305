export class Request {
    public resolve: Function
    public error: Function
    public requestBody: string

    public constructor(resolve: Function, error: Function, body: string) {
        this.resolve = resolve
        this.error = error
        this.requestBody = body
    }
}
