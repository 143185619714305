export class FWSError extends Error {
    constructor($remoteMessage: string, $usedMethod: string, $usedPacketId: Number) {
        super()
        this._remoteMessage = $remoteMessage
        this._usedMethod = $usedMethod
        this._usedPacketId = $usedPacketId
    }

    private _remoteMessage: string
    public get remoteMessage(): string {
        return this._remoteMessage
    }

    public set remoteMessage(value: string) {
        this._remoteMessage = value
    }

    private _usedMethod: string
    public get usedMethod(): string {
        return this._usedMethod
    }

    public set usedMethod(value: string) {
        this._usedMethod = value
    }

    private _usedPacketId: Number
    public get usedPacketId(): Number {
        return this._usedPacketId
    }

    public set usedPacketId(value: Number) {
        this._usedPacketId = value
    }
}
