import store from "../store"
import {enqueueSnackbar} from "./redux/actions/SnacksActions"

export class Snacks {
    public static warning(message: string, options = {}) {
        store.dispatch(enqueueSnackbar({message: message, options: {...options, variant: 'warning'}}))
    }

    public static error(message: string, options = {}) {
        store.dispatch(enqueueSnackbar({message: message, options: {...options, variant: 'error'}}))
    }

    public static success(message: string, options = {}) {
        store.dispatch(enqueueSnackbar({message: message, options: {...options, variant: 'success'}}))
    }

    public static info(message: string, options = {}) {
        store.dispatch(enqueueSnackbar({message: message, options: {...options, variant: 'info'}}))
    }
}