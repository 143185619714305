import {Grid} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {useHistory} from "react-router-dom"
import {MainAPI} from "../core/api/FApi"
import {t} from "../core/lang/lang"
import {connect} from "react-redux";

const RolesPage = (props) => {
    //var columns = null
    /*[
      { title: "id", field: "id", hidden: true },
      { title: "Name", field: "name" },
      { title: "Login", field: "email" },
      { title: "Role", field: "role" },
    ]*/
    const history = useHistory()
    const [columns, setColumns] = React.useState(null)

    const hasWritePermission = props.userOptions?.perms?.includes("table_roles_rw")

    if (columns == null) {
        MainAPI.editorsGetFields("role").then((data) => {
            setColumns(data.fields)
        }).catch(()=>{})
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <Grid container spacing={1}>
                <Grid item xs={1}/>
                <Grid item xs={12}>
                    <MaterialTable
                        title={t("ROLES")}
                        columns={columns}
                        options={{
                            pageSize: 10, search: false, filtering: true
                        }}
                        data={(query) =>
                            MainAPI.rolesList(query).then((result) => {
                                return {
                                    data: result.result,
                                    page: query.page,
                                    totalCount: result.count,
                                }
                            })
                        }
                        actions={hasWritePermission ? [
                            {
                                icon: "settings",
                                tooltip: "Role permissions",
                                onClick: (event, rowData) => {
                                    history.push("/roles/edit/" + rowData["id"])
                                },
                            },
                        ] : []}
                        icons={props.tableIcons}
                        editable={hasWritePermission ? {
                            onRowUpdate: (newData) => MainAPI.rolesUpdate(newData),
                            onRowAdd: (newData) => MainAPI.rolesCreate(newData),
                            onRowDelete: (req: any) => MainAPI.rolesDelete(req.id),
                        } : {}}
                    />
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(RolesPage)
