import {
    CLOSE_ALERT,
    PAGE_SWITCH,
    SET_THEME,
    SHOW_ALERT,
    SHOW_DEVICE_INFO_ALERT,
    SHOW_EXPORT_ALERT,
    SHOW_REPORT_ALERT,
} from "../actions/PageStateActions"
import {MainAPI} from "../../api/FApi"

export default function currentPage(state = initialState, action) {
    //alert("Action "+JSON.stringify(action))
    switch (action.type) {
        case PAGE_SWITCH:
            return {
                ...state,
                page: action.payload.page,
            }
        case SHOW_ALERT:
            return {
                ...state,
                notificationOpen: true,
                notificationType: 1,
                notificationData: action.payload,
            }
        case SHOW_REPORT_ALERT:
            return {
                ...state,
                notificationOpen: true,
                notificationType: 3,
                notificationData: action.payload,
            }
        case SHOW_DEVICE_INFO_ALERT:
            return {
                ...state,
                notificationOpen: true,
                notificationType: 4,
                notificationData: action.payload,
            }
        case CLOSE_ALERT:
            return {
                ...state,
                notificationType: 0,
                notificationOpen: false,
            }
        case SHOW_EXPORT_ALERT:
            return {
                ...state,
                notificationOpen: true,
                notificationType: 2,
                notificationData: action.payload,
            }
        case SET_THEME:
            return {
                ...state,
                darkThemeEnabled: action.payload,
            }
    }
    return state
}

const initialState = {
    page: 0,
    darkThemeEnabled: false,
    notificationOpen: false,
    notificationType: 0,
    exportOpen: false,
    notificationData: null,
}
