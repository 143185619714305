import {Grid} from "@material-ui/core"
import * as React from "react"
import MaterialTable from "@material-table/core"
import {useHistory} from "react-router-dom"
import {MainAPI} from "../core/api/FApi"
import {t} from "../core/lang/lang"
import store from "../store"
import {showExportAlertAction} from "../core/redux/actions/PageStateActions"
import {connect} from "react-redux";

const generateExport = (kind, request) => {
    store.dispatch(showExportAlertAction(kind, request))
}

const AccountsPage = (props) => {
    const history = useHistory()
    const [columns, setColumns] = React.useState(null)

    const hasWritePermission = props.userOptions?.perms?.includes("table_users_rw")

    let lastQueryParams = {}

    if (columns == null) {
        MainAPI.editorsGetFields("user").then((data) => {
            setColumns(data.fields)
        }).catch(()=>{})
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <Grid container spacing={1}>
                <Grid item xs={1}/>
                <Grid item xs={12}>
                    <MaterialTable
                        title={t("ACCOUNTS")}
                        columns={columns}
                        options={{
                            pageSize: 10,
                            search: false, filtering: true,// exportButton: {csv: true},
                            exportMenu: [{
                                label: 'Export Excel',
                                exportFunc: () => generateExport("accounts", lastQueryParams)
                            }]
                            // exportCsv: (columns, data) =>
                            //     generateExport("accounts", lastQueryParams)
                        }}
                        // localization={{toolbar: {exportCSVName: "Export Excel"}}}
                        data={(query) => {
                            lastQueryParams = query
                            return MainAPI.accountsList(query).then((result) => {
                                return {
                                    data: result.result,
                                    page: query.page,
                                    totalCount: result.count,
                                }
                            })
                        }
                        }
                        actions={hasWritePermission ? [
                            {
                                icon: "settings",
                                tooltip: "User settings",
                                onClick: (event, rowData) => {
                                    history.push("/accounts/edit/" + rowData["id"])
                                },
                            },
                        ] : []}
                        icons={props.tableIcons}
                        editable={hasWritePermission ? {
                            onRowUpdate: (newData) => MainAPI.accountsUpdate(newData),
                            onRowAdd: (newData) => MainAPI.accountsCreate(newData),
                            onRowDelete: (req: any) => MainAPI.accountsDelete(req.id),
                        } : {}}
                    />
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(AccountsPage)