import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper, {PaperProps} from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Slide from "@material-ui/core/Slide"
import {TransitionProps} from "@material-ui/core/transitions"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import {MainAPI} from "../core/api/FApi"
import {Grid} from "@material-ui/core"
import {showDeviceInfoAlertAction} from "../core/redux/actions/PageStateActions"
import store from "../store"

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export function showDeviceInfo(deviceId) {
    store.dispatch(showDeviceInfoAlertAction({deviceId: deviceId}))
}

export default function DeviceInfoAlert(props) {
    const [data, setData] = React.useState(null)
    const [fields, setFields] = React.useState([])

    const updateData = () => {
        MainAPI.devicesGet(props.data?.deviceId).then((data) => {
            setData(data)
        }).catch(()=>{})
        MainAPI.editorsGetFields("device").then((data) => {
            setFields(data.fields)
        }).catch(()=>{})
    }

    if (props.data?.deviceId === undefined && data != null) {
        //console.log("setdata")
        setData(null)
    }
    if (props.data?.deviceId !== undefined && data == null) {
        // console.log("updata")
        updateData()
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            keepMounted
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                Модуль №{data?.serial_number}
            </DialogTitle>
            <DialogContent>
                {
                    (data == null) ?
                        <Grid container spacing={1}>
                            <Grid item xs={1}/>
                            <Grid item xs={12}>
                                Загрузка...
                            </Grid>
                            <Grid item xs={1}/>
                        </Grid>
                        :
                        // <table>
                        //     <tr>
                        //         <th>Параметр</th>
                        //         <th>Значение</th>
                        //     </tr>
                        //     {fields.map((element, index) => 
                        //     <tr><td>{element.title}</td><td>{data[element.field]}</td></tr>
                        //     )}
                        // </table>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="datas">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Параметр</TableCell>
                                        <TableCell align="center">Значение</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((element, index) =>
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {element.title}
                                            </TableCell>
                                            <TableCell align="center">{data[element.field]}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

