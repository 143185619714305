import React from "react"
import PropTypes from "prop-types"
import {withStyles} from "@material-ui/core/styles"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import store from "../store"
import {connect} from "react-redux"
import {setThemeAction} from "../core/redux/actions/PageStateActions"
import {MainAPI} from "../core/api/FApi";

const styles = ({spacing, transitions, breakpoints, palette, shape}) => ({
    header: {
        fontWeight: 900,
        minWidth: 0,
        fontSize: 18
    },
    grow: {
        flexGrow: 1
    },
    search: {
        position: "relative",
        marginRight: 8,
        borderRadius: shape.borderRadius,
        background: palette.grey[200],
        "&:hover": {
            background: palette.grey[300]
        },
        marginLeft: 0,
        width: "100%",
        [breakpoints.up("sm")]: {
            marginLeft: spacing(1),
            width: "auto"
        }
    },
    searchIcon: {
        width: spacing(9),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    inputInput: {
        borderRadius: 4,
        paddingTop: spacing(1),
        paddingRight: spacing(1),
        paddingBottom: spacing(1),
        paddingLeft: spacing(10),
        transition: transitions.create("width"),
        width: "100%",
        [breakpoints.up("sm")]: {
            width: 120,
            "&:focus": {
                width: 200
            }
        }
    }
})

const changeTheme = (state) => {
    store.dispatch(setThemeAction(state))
    if (MainAPI.isConnected)
        MainAPI.userSetTheme(state).then(() => {
        }).catch(()=>{})
}

const HeaderEx = (props) => (
    <>
        <Typography noWrap color={"textPrimary"} className={props.classes.header}>
            TMonitor
        </Typography>
        <div className={props.classes.grow}/>
        <IconButton onClick={() => changeTheme(!props.darkThemeEnabled)}>
            <Icon>{props.darkThemeEnabled ? "brightness_7" : "brightness_2"}</Icon>
        </IconButton>
        {/*<div className={classes.search}>
      <div className={classes.searchIcon}>
        <Icon>search</Icon>
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
      />
      </div>
    {screen === "xs" && (
      <IconButton>
        <Icon>more_vert</Icon>
      </IconButton>
    )}
    {screen === "sm" && (
      <>
        <IconButton>
          <Icon>favorite</Icon>
        </IconButton>
        <IconButton>
          <Icon>more_vert</Icon>
        </IconButton>
      </>
    )}
    {isWidthUp("md", screen) && (
      <>
        <IconButton>
          <Icon>favorite</Icon>
        </IconButton>
        <IconButton>
          <Icon>phone</Icon>
        </IconButton>
        <IconButton>
          <Icon>camera</Icon>
        </IconButton>
      </>
    )}*/}
    </>
)

HeaderEx.propTypes = {
    screen: PropTypes.string,
    classes: PropTypes.shape({}).isRequired
}
HeaderEx.defaultProps = {
    screen: null
}

const mapStateToProps = (state) => {
    return {
        darkThemeEnabled: state.currentPage.darkThemeEnabled,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(HeaderEx))
