import {reportsTableRef} from "../../pages/PageLastReports"
import {Log} from "../../utils/Log"
import {Snacks} from "../Snacks"
import {FApi} from "../api/FApi";

export function consumeNetworkEvent(name: string, data: any) {
    Log.log("Got event " + name + " " + data)
    switch (name) {
        case "userchanged":
            FApi.updateUserInfo()
            FApi.updateUserData()
            break
        case "reportschanged":
            reportsTableRef.current.onQueryChange()
            break
        case "popup":
            Snacks.info(data.t)
            break
    }
}