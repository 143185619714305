/* eslint-disable */
export class BiKeyMap<T> {
    private readonly __map__: Map<string, T>[]

    constructor() {
        this.__map__ = []
        this.get = this.get.bind(this)
        this.set = this.set.bind(this)
        this.remove = this.remove.bind(this)
        /*this.keys = this.keys.bind(this)
        this.nestedKeys = this.nestedKeys.bind(this)
        this.clear = this.clear.bind(this)*/
    }

    public get(key: number, nestedKey: string): T | undefined {
        if (!this.__map__[key] || this.__map__[key] && !this.__map__[key].get(nestedKey))
            return

        return this.__map__[key].get(nestedKey)
    }

    public set(key: number, nestedKey: string, value: T): void {
        if (!this.__map__[key]) {
            this.__map__[key] = new Map()
        }

        this.__map__[key].set(nestedKey, value)
    }

    public remove(key: number, nestedKey: string): void {
        if (!this.__map__[key]) {
            return
        }

        this.__map__[key].delete(nestedKey)
    }

}