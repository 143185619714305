import {Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core"
import * as React from "react"
import {ChangeEvent} from "react"
import {useHistory, useParams} from "react-router"
import {MainAPI} from "../core/api/FApi"
import {t} from "../core/lang/lang"
import {connect} from "react-redux";

const AccountEditor = (props) => {
    const [account, setAccount] = React.useState({id: -1, name: "Loading...", role_id: 0})
    const [locked, setLocked] = React.useState(false)
    let newPassword = ""
    useHistory()
    let {userid} = useParams<Record<string, string | undefined>>()
    const [roles, setRoles] = React.useState(null)

    const hasWritePermission = props.userOptions?.perms?.includes("table_users_rw")

    const updateAccount = () => {
        MainAPI.accountsGet(userid).then((account) => {
            setAccount(account)
        }).catch(()=>{})
    }
    const updateRoles = () => {
        MainAPI.rolesList({pageSize: 300}).then((roles) => {
            console.log(roles)
            setRoles(roles.result)
        }).catch(()=>{})
        return null
    }

    if (account.id < 0) updateAccount()
    if (roles == null) updateRoles()

    const onElementChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        newPassword = event.target.value
    }
    const handleChange = (event) => {
        //setAge(event.target.value as string)
        //console.log("Changed to " + event.target.value)
        account.role_id = event.target.value
        setAccount(account)
        setLocked(true)
        MainAPI.userApplyRole(account.id, account.role_id).finally(() => setLocked(false))
    }

    return hasWritePermission ? (
        <Grid container spacing={1}>
            <Grid item xs={1}/>
            <Grid item xs={6}>
                <h1>Пользователь #{account.id}</h1>
                <Grid container direction="column" spacing={1}>
                    {roles != null ?
                        <Grid item>
                            <FormControl>
                                <InputLabel id="role-sel-lbl">Role</InputLabel>
                                <Select
                                    labelId="role-sel-lbl"
                                    id="role-sel"
                                    value={account.role_id}
                                    disabled={locked}
                                    onChange={handleChange}
                                >
                                    {roles.map((elem) => <MenuItem value={elem.id}>{elem.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid> : <></>}
                    <Divider style={{margin: "12px 0"}}/>
                    <Grid item>
                        <TextField
                            label={t("NEW_PASSWORD")}
                            variant="outlined"
                            onChange={onElementChange}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                //set new password
                                MainAPI.setUserPassword(account.id, newPassword).then(r => console.log("Password set. " + r)).catch(()=>{})
                            }}
                        >
                            {t("CHANGE_PASSWORD")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    ) : <></>
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(AccountEditor)
