import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper, {PaperProps} from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Slide from "@material-ui/core/Slide"
import {TransitionProps} from "@material-ui/core/transitions"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function ReportAlert(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            keepMounted
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                {props.data?.title}
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="processes">
                        <TableHead>
                            <TableRow>
                                <TableCell>Процесс</TableCell>
                                <TableCell align="center">Результат</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Самодиагностика Модуля
                                </TableCell>
                                <TableCell align="center">{props.data?.selfDiag}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Установление рабочего режима Модуля
                                </TableCell>
                                <TableCell align="center">{props.data?.actMode}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Определение наличия газа в ГР
                                </TableCell>
                                <TableCell align="center">{props.data?.gasPres}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Определение горючести газа из ГР
                                </TableCell>
                                <TableCell align="center">{props.data?.gasFlame}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
