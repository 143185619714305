
import {FirmwaresList} from "../../pages/FirmwaresList"
import {PermissionEditor} from "../../pages/PermissionEditor"
import {RoleEditorPage} from "../../pages/RoleEditorPage"
import TOIRPage from "../../pages/TOIRPage"
import {t} from "../lang/lang"
import {SettingsPage} from "../../pages/SettingsPage";
import PageLastReports from "../../pages/PageLastReports";
import DevicesPage from "../../pages/DevicesPage";
import DiagnosticsArchive from "../../pages/DiagnosticsArchive";
import AccountEditor from "../../pages/AccountEditor";
import AccountsPage from "../../pages/AccountsPage";
import RolesPage from "../../pages/RolesPage";

const Routes = [
    {
        path: "/",
        sidebarName: t("REPORTS"),
        icon: "report",
        perm: /table_reports_*/,
        component: PageLastReports,
    },
    {
        path: "/devices",
        icon: "router",
        perm: /table_devices_*/,
        sidebarName: t("DEVICES"),
        component: DevicesPage,
    },
    {
        path: "/history",
        icon: "history",
        perm: /table_reports_*/,
        sidebarName: t("DIAGNOSTICS"),
        component: DiagnosticsArchive,
    },
    {
        path: "/accounts",
        icon: "people",
        perm: /table_users_*/,
        sidebarName: t("ACCOUNTS"),
        component: AccountsPage,
    },
    {
        path: "/roles",
        icon: "vpn_key",
        perm: /table_roles_*/,
        sidebarName: t("ROLES"),
        component: RolesPage,
    },
    // {
    //   path: "/api",
    //   icon: "vpn_key",
    //   sidebarName: "API Testing",
    //   component: APITester,
    // },
    {
        path: "/firmwares",
        icon: "system_update",
        perm: /developer_abilities/,
        sidebarName: t('FIRMWARES'),
        component: FirmwaresList,
    },
    {
        path: "/settings",
        perm: /settings_*/,
        icon: "settings",
        placeDelimiter: true,
        sidebarName: t("SETTINGS"),
        component: SettingsPage,
    },
    {
        path: "/accounts/edit/:userid",
        perm: /table_users_rw/,
        component: AccountEditor,
    },
    {
        path: "/roles/edit/:roleid",
        perm: /table_roles_rw/,
        component: RoleEditorPage,
    },
    {
        path: "/toir/:devid",
        perm: /table_devices_*/,
        component: TOIRPage,
    },
    // {
    //     path: "/device/:devid",
    //     perm: "table_reports_rw",
    //     component: HistDetailPage,
    // },
    {
        path: "/accounts/edit/:userid/permissions",
        perm: /table_roles_*/,
        component: PermissionEditor,
    },
]

export default Routes
