import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper, {PaperProps} from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Slide from "@material-ui/core/Slide"
import {TransitionProps} from "@material-ui/core/transitions"

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function NotificationAlert(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            keepMounted
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                {props.data?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.data?.extended}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
