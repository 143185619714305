import * as React from "react"
import {LoginEntity} from "../core/model/login"
import {LoginComponent} from "./login.component"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import {NotificationComponent} from "../common"
import {MainAPI} from "../core/api/FApi"
import {FWSError} from "../core/ws/FWSError"
import {selectPageAction} from "../core/redux/actions/PageStateActions"
import {connect} from "react-redux"

// https://material-ui.com/styles/api/#makestyles-styles-options-hook
const useFormStyles = makeStyles((theme) =>
    createStyles({
        card: {
            maxWidth: 400,
            margin: "0 auto",
        },
    })
)

interface Props {
    selectPageAction: typeof selectPageAction
}

const LoginContainer: React.FC<Props> = (props) => {
    const [isShowAlert, setShowAlert] = React.useState(false)
    const [alertText, setAlertText] = React.useState(
        "Invalid login or password, please type again"
    )
    const classes = useFormStyles()

    const handleLogin = (login: LoginEntity) => {
        MainAPI.authByForm(login)
            .then((tok) => {
            })
            .catch((err) => {
                if (err instanceof FWSError) {
                    setAlertText(err.remoteMessage)
                    setShowAlert(true)
                }
            })
    }

    return (
        <>
            <Card className={classes.card}>
                <CardHeader title="Login"/>
                <CardContent>
                    <LoginComponent onLogin={handleLogin}/>
                    <NotificationComponent
                        show={isShowAlert}
                        message={alertText}
                        onClose={() => setShowAlert(false)}
                    />
                </CardContent>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentPage: state.currentPage,
    }
}

export default connect(mapStateToProps, {selectPageAction})(LoginContainer)
