import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Icon from "@material-ui/core/Icon"
import Divider from "@material-ui/core/Divider"
import {Link, withRouter} from "react-router-dom"
import {matchPath} from "react-router"
import {connect} from "react-redux"
import Routes from "../core/routes/Routes";
import {MainAPI} from "../core/api/FApi";
import {ExitToApp} from "@material-ui/icons";
import {Button} from "@material-ui/core";

const NavContentEx = (props) => {
    const activeRoute = (routeName: string) => {
        return (
            matchPath(props.location.pathname, {
                path: routeName,
                exact: true,
                strict: false,
            }) !== null
        )
    }

    return (
        <List>
            {Routes.filter((e) => e.sidebarName !== undefined && (e.perm == null || props.userOptions?.perms?.find(a => e.perm.test(a)))).map(({
                                                                                                                                          sidebarName,
                                                                                                                                          path,
                                                                                                                                          icon,
                                                                                                                                          placeDelimiter
                                                                                                                                      }, i) => (
                <>
                    {placeDelimiter ? <Divider style={{margin: "12px 0"}}/> : <></>}
                    <ListItem
                        key={sidebarName}
                        selected={activeRoute(path)}
                        component={Link}
                        to={path}
                        button
                    >
                        <ListItemIcon>
                            <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={sidebarName}
                            primaryTypographyProps={{noWrap: true}}
                        />
                    </ListItem>
                </>
            ))}
            {props.userOptions?.perms?.includes("settings_rw") ? <></> :
                <ListItem
                    key={"logout"}
                    selected={false}
                    component={Link}
                    onClick={() => {
                        MainAPI.logout()
                        window.location.reload()
                    }}
                    button
                >
                    <ListItemIcon>
                        <ExitToApp/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Выйти из аккаунта"}
                        primaryTypographyProps={{noWrap: true}}
                    />
                </ListItem>}
        </List>
    )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}
export default connect(mapStateToProps)(withRouter(NavContentEx))
