import React, {useEffect, useRef, useState} from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper, {PaperProps} from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Slide from "@material-ui/core/Slide"
import {TransitionProps} from "@material-ui/core/transitions"
import {MainAPI} from "../core/api/FApi";
import store from "../store";
import {showExportAlertAction} from "../core/redux/actions/PageStateActions";
import {TextField, Typography} from "@material-ui/core";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

enum ExportState {
    EXPORT_SELECT_COUNT,
    EXPORT_LOADING,
    EXPORT_READY
}

export default function ExportAlert(props) {
    const [exportState, setExportState] = useState<ExportState>(ExportState.EXPORT_SELECT_COUNT)
    const [exportedLocation, setExportedLocation] = useState<string>("")

    useEffect(() => {
        setExportState(ExportState.EXPORT_SELECT_COUNT)
        setExportedLocation("")
    }, [props])
    const generateExport = () => {
        if (!isFinite(countRef.current)) { alert("Неверный формат числа"); return }
        setExportState(ExportState.EXPORT_LOADING)
        MainAPI.exportFile(props.data.kind, props.data.request, countRef.current).then((data) => {
                setExportedLocation(window.location.protocol + "//" + window.location.hostname + ":8880/dl.php?t=" + data.descriptor)
                setExportState(ExportState.EXPORT_READY)
            }
        ).catch(()=>{setExportState(ExportState.EXPORT_SELECT_COUNT)})
    }
    const countRef = useRef(10)

    let dialogContent;
    switch (exportState) {
        case ExportState.EXPORT_LOADING:
            dialogContent = <Typography>Идёт подготовка отчёта, подождите...</Typography>
            break
        case ExportState.EXPORT_READY:
            dialogContent = <Typography>Отчёт готов, нажмите "Загрузить"</Typography>
            break
        case ExportState.EXPORT_SELECT_COUNT:
            dialogContent = <TextField
                label="Выберите количество строк для экспорта"
                defaultValue={countRef.current}
                style={{width: "100%"}}
                variant="outlined"
                onChange={(val) => {
                    countRef.current = parseInt(val.target.value)
                }}
            />
            break
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            keepMounted
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                Export
            </DialogTitle>
            <DialogContent>
                <DialogContent>
                    {
                        dialogContent
                    }
                </DialogContent>
            </DialogContent>
            <DialogActions>
                {exportState !== ExportState.EXPORT_LOADING && <Button autoFocus href={exportedLocation} onClick={exportState === ExportState.EXPORT_READY ? props.handleClose : generateExport} color="primary">
                    {exportState === ExportState.EXPORT_READY ? "Загрузить" : "Создать отчёт"}
                </Button>}
            </DialogActions>
        </Dialog>
    )
}
